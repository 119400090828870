import { deepMap, listenKeys } from '@podium/store';
import {
  AdConfigIntermingle,
  AdStatus,
  AdType,
  ClientAdPlacement,
  PlacementId
} from '@schibsted-nmp/advertising-shared';

import { AtomChannels, AtomTopics } from './index';

export const $placementsMap = deepMap<Record<string, ClientAdPlacement>>(
  AtomChannels.Podlet,
  AtomTopics.PlacementsMap,
  {}
);

export const $statusSequence = deepMap<Record<string, AdStatus[]>>(
  AtomChannels.Podlet,
  AtomTopics.AdStatusSequence,
  {}
);

export function updatePlacementKeyValueById(
  placementId: PlacementId,
  updateKey: keyof ClientAdPlacement,
  updateValue: AdStatus | AdType
) {
  const currentPlacement = $placementsMap.get()[placementId];
  const hasSameValue =
    currentPlacement && currentPlacement[updateKey] === updateValue;
  if (!currentPlacement || hasSameValue) {
    return;
  }

  $placementsMap.setKey(`${placementId}.${updateKey}`, updateValue);
}

export function listenPlacementKeysForValueChange<
  K extends keyof ClientAdPlacement
>(
  placementId: PlacementId,
  updateKey: K,
  callback: (
    value: ClientAdPlacement[K],
    oldValue?: ClientAdPlacement[K]
  ) => void
) {
  return listenKeys(
    $placementsMap,
    [`${placementId}.${updateKey}`],
    (placements, oldPlacements) => {
      const placement = placements[placementId];
      const oldPlacement = oldPlacements[placementId];
      const value = placement[updateKey];
      const oldValue = oldPlacement?.[updateKey];
      callback(value, oldValue);
    }
  );
}

export function addStatusToSequence(
  placementId: PlacementId,
  status: AdStatus
) {
  const currentSequence = $statusSequence.get()[placementId] || [];
  $statusSequence.setKey(`${placementId}`, [...currentSequence, status]);
}

export function getPlacementList(): ClientAdPlacement[] {
  return Object.values($placementsMap.get())?.filter(
    (placement) => placement?.placementId
  );
}

export function getPlacementById(
  placementId: PlacementId
): ClientAdPlacement | undefined {
  return $placementsMap.get()[placementId];
}

function updatePlacementsStatus(
  placements: ClientAdPlacement[],
  status: AdStatus
): Record<string, ClientAdPlacement> {
  return placements.reduce(
    (acc, placement) => {
      acc[placement.placementId] = {
        ...placement,
        status
      };
      return acc;
    },
    {} as Record<string, ClientAdPlacement>
  );
}

export function getRequestPlacements() {
  return getPlacementList().filter(
    (placement) => placement.status === 'request'
  );
}

export function getInterminglePlacements() {
  return getPlacementList().filter((placement) => {
    const intermingle = placement.intermingle as AdConfigIntermingle;
    if (!intermingle) return false;

    // Recirc ads are not loaded as intermingle placements anymore. Instead, they are set up by the recommendation API
    // and rendered from the components in advertising-companion (AdvertisingWebComponent).
    const isRecircAd =
      placement.placementId.includes('recirc') ||
      placement.placementId.includes('ad-stream');
    if (isRecircAd) return false;
    return Boolean(intermingle.list && intermingle.grid);
  });
}

export function updateAllPlacementStatuses(status: AdStatus) {
  const allPlacements = getPlacementList();
  const updatedPlacements = updatePlacementsStatus(allPlacements, status);
  $placementsMap.set(updatedPlacements);
}

export function updatePlacementStatuses(
  placementList: ClientAdPlacement[],
  status: AdStatus
) {
  placementList.forEach((placement) => {
    $placementsMap.setKey(`${placement.placementId}.status`, status);
  });
}
