import classNames from 'classnames';
import { css } from 'lit';

import { AdStatus, AdType } from '@schibsted-nmp/advertising-shared';

export function getClassNameByStatus(status: AdStatus) {
  return classNames({
    'advt-loaded': status === 'available' || status === 'loaded',
    'advt-empty': status === 'error' || status === 'collapsedOnCollision'
  });
}

export function getClassNameByType(type: AdType) {
  return classNames({
    'native-ad': type === 'native',
    'video-ad': type === 'video',
    'banner-ad': type === 'banner' || type === 'adnami',
    'sponsored-content': type === 'sponsored-content',
    'takeover-ad': type === 'takeover',
    'intermingles-ad': type === 'intermingles'
  });
}

export default css`
  :host {
  }
`;
