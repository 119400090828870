import React, { useMemo } from 'react';

import { getPlacementList } from '@client/core/atoms/placements';

export function ErrorTab() {
  const placements = getPlacementList();
  const errorList = useMemo(
    () =>
      [
        placements?.length === 0
          ? "No advertising loaded because server didn't return any placement"
          : null
      ].filter((e) => !!e),
    []
  );

  return (
    <div className="flex flex-col gap-2">
      <h5>Errors</h5>
      {errorList.length > 0 ? (
        <ul className="text-14 flex flex-col gap-4">
          {errorList.map((errorMsg) => (
            <li key={errorMsg} className="s-bg-primary p-4 break-all">
              {errorMsg}
            </li>
          ))}
        </ul>
      ) : (
        <p>No errors</p>
      )}
      <hr />
    </div>
  );
}

ErrorTab.friendlyName = 'Errors' as const;
