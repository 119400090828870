import { html } from 'lit';

import { AdVendor } from '@client/core/AdVendor';
import { AdServer, PlacementId } from '@schibsted-nmp/advertising-shared';
import { renderAdnAd } from '@client/adnuntius/AdUnit/renderAd';

export class AdnAdVendor extends AdVendor {
  placementId: PlacementId;

  constructor(placementId: PlacementId) {
    super();
    this.adServer = AdServer.ADN;
    this.placementId = placementId;
    this.containerId = `${placementId}--container`;
  }

  updated(): void {}

  cleanupEvents() {}

  setupEvents() {}

  requestAd(): void {
    renderAdnAd({
      placementId: this.placementId,
      containerId: this.containerId || `${this.placementId}--container`
    });
  }

  render() {
    return html`
      <slot
        name="${`${this.placementId}--slot`}"
        id="${this.placementId}"
      ></slot>
    `;
  }
}
