import React from 'react';

import { ConsentStatus } from '@schibsted-nmp/advertising-shared';
import { $initialState } from '@client/core/atoms/config';
import { TcfApiMock } from '@client/TcfApiMock';
import { TcfHelperMock } from '@client/TcfHelperMock';
import { $consentAtom } from '@client/core/atoms/consent';

export function ConsentTab() {
  const { env } = $initialState.get();
  const consented = $consentAtom.get();

  const toggleConsent = React.useCallback(() => {
    const nextConsentState =
      $consentAtom.get() === ConsentStatus.Rejected
        ? 'Consented'
        : 'NonConsented';

    TcfApiMock.TcData.set(nextConsentState);
    TcfHelperMock.ConsentState.set(nextConsentState);

    sessionStorage.setItem('ADVT_DEV_TOOL_consentState', nextConsentState);

    // TODO: Should be removed when we've implemented reacting to consent changes:
    window.location.reload();
  }, []);

  if (env !== 'local') {
    return (
      <ConsentTab.Wrapper>
        <p>
          Mock consent is only active in a local environment. Use the regular
          CMP dialog to change consent.
        </p>
      </ConsentTab.Wrapper>
    );
  }

  return (
    <ConsentTab.Wrapper>
      <p>Current status: {consented}</p>

      <button
        className="bg-primary text-inverted p-16 rounded"
        onClick={toggleConsent}
      >
        Toggle consent and refresh page
      </button>
    </ConsentTab.Wrapper>
  );
}

export namespace ConsentTab {
  export const friendlyName = 'Consent' as const;

  export function Wrapper({ children }: Wrapper.Props) {
    return (
      <div className="p-16">
        <h5>Consent</h5>
        {children}
      </div>
    );
  }

  export namespace Wrapper {
    export type Props = { children: React.ReactNode };
  }
}
