import type { PrebidRequester } from './PrebidRequester';
import { Targeting } from './Targeting';
import type { CustomSettings } from './CustomSettings';
import type { ConsentManagement } from './ConsentManagement';
import type { Pbjs } from '../Pbjs';

export namespace BidderSpecific {
  export namespace Settings {
    export function* get<TMap extends CustomSettings.Map>(
      bidderCodeToCustomSettingsMap: TMap
    ) {
      for (const [
        bidderCode,
        { revenueFactor }
      ] of bidderCodeToCustomSettingsMap) {
        if (!revenueFactor) continue;

        yield [
          bidderCode,
          {
            bidCpmAdjustment(bidCpm, bidResponse, _bidRequest) {
              return bidResponse?.netRevenue ? bidCpm : bidCpm * revenueFactor;
            }
          }
        ] as const satisfies readonly [
          bidderCode: string,
          bidderSettings: Pbjs.BidderSettings.Value
        ];
      }
    }

    export function apply<TMap extends CustomSettings.Map>(
      pbjs: PrebidRequester['pbjs'],
      bidderCodeToCustomSettingsMap: TMap
    ) {
      pbjs.que.push(() => {
        const bidderSettings = (pbjs.bidderSettings ??= {});

        for (const [bidderCode, bidderSpecificSettings] of get(
          bidderCodeToCustomSettingsMap
        )) {
          bidderSettings[bidderCode] = bidderSpecificSettings;
        }

        const standard = (bidderSettings.standard ??= {});
        standard.adserverTargeting ??= Array.from(Targeting.Mappers.get(pbjs));
        standard.storageAllowed = true;
      });
    }
  }

  export namespace Configs {
    export function apply(
      pbjs: PrebidRequester['pbjs'],
      customConsentVendorsMap: ConsentManagement.CustomVendors.Map
    ) {
      pbjs.que.push(() => {
        for (const [
          bidderCode,
          { schainNodes: nodes }
        ] of customConsentVendorsMap) {
          pbjs.setBidderConfig({
            bidders: [bidderCode],
            config: {
              schain: {
                validation: 'relaxed',
                config: { ver: '1.0', complete: 1, nodes }
              }
            }
          });
        }
      });
    }
  }
}
