import React, { ReactNode, useEffect, useState } from 'react';

import classNames from 'classnames';

import { $statusSequence } from '@client/core/atoms/placements';
import { listenKeys } from '@podium/store';
import {
  AdStatus,
  ClientAdPlacement,
  getSizesByMediaType,
  PlacementId
} from '@schibsted-nmp/advertising-shared';

export function PlacementItem({
  placement,
  listExpanded
}: {
  placement: ClientAdPlacement;
  listExpanded: boolean;
}) {
  const { placementId } = placement;
  const [adStatusSequence, setAdStatusSequence] = useState<AdStatus[]>([]);
  const [expanded, setExpanded] = useState(listExpanded);

  useEffect(() => {
    setExpanded(listExpanded);
  }, [listExpanded]);

  useEffect(() => {
    const unsubscribe = listenKeys(
      $statusSequence,
      [`${placementId}`],
      (value) => {
        const placementSequence = value[placementId];
        if (Array.isArray(placementSequence)) {
          setAdStatusSequence(placementSequence);
        }
      }
    );
    return unsubscribe;
  }, [placementId]);

  const renderInfo = ({
    label,
    shouldHide,
    value
  }: {
    label: string;
    shouldHide?: boolean;
    value: string | ReactNode;
  }) =>
    shouldHide ? null : (
      <div className="flex flex-col text-s gap-0 text-align-center justify-self-start">
        <small>{label}:</small>
        <b>{value}</b>
      </div>
    );

  const isNoFill = adStatusSequence.includes('error');

  const placementSizes =
    placement.adServer.type === 'gam'
      ? getSizesByMediaType(placement.adServer.config?.mediaTypes || [])
      : [];

  const handlePlacementClick = () => {
    showPlacement(placementId);
  };

  const statusText = `${placement.status} ${isNoFill ? '(No fill)' : ''}`;
  return (
    <li
      onClick={handlePlacementClick}
      className={classNames('cursor-pointer border p-4 rounded-4', {
        's-border-positive s-bg-positive-subtle':
          placement?.status === 'loaded',
        's-border-negative s-bg-negative-subtle':
          placement?.status === 'error' && !isNoFill,
        's-border s-bg-subtle': isNoFill
      })}
    >
      <div className="flex flex-row justify-between">
        <h4>
          {placementId}{' '}
          {!expanded && (
            <span className="ml-4 text-xs text-gray-500">{statusText}</span>
          )}
        </h4>
        <button
          onClick={() => setExpanded(!expanded)}
          className="ml-4 text-xs bg-transparent border-bottom"
        >
          {expanded ? 'Collapse' : 'Expand'}
        </button>
      </div>

      {expanded && (
        <>
          {placement && (
            <div className="grid grid-cols-3 text-caption">
              {[
                {
                  label: 'Status',
                  value: isNoFill ? (
                    'No fill'
                  ) : (
                    <div
                      className={classNames('', {
                        's-text-negative': placement.status === 'error',
                        's-text-positive': placement.status === 'loaded'
                      })}
                    >
                      {statusText}
                    </div>
                  )
                },
                {
                  label: 'Ad Server',
                  value: placement.adServer.type
                },
                {
                  label: 'AdType',
                  value: placement.creativeType || 'not set'
                },
                {
                  label: 'Intermingle',
                  shouldHide:
                    !placement?.intermingle?.grid &&
                    !placement?.intermingle?.list,
                  value: `Grid: ${placement.intermingle?.grid} | List: ${placement.intermingle?.list}`
                },
                {
                  label: 'Sizes',
                  value: placementSizes.length
                    ? placementSizes
                        .map((size) =>
                          Array.isArray(size) ? size.join('x') : size
                        )
                        .join(', ')
                    : 'not set'
                }
              ].map((info) => renderInfo(info))}
            </div>
          )}

          <div className="text-s mt-8">Ad Status sequence:</div>
          <div className="flex flex-row flex-wrap gap-4">
            {adStatusSequence?.length ? (
              adStatusSequence.map((status, index) => (
                <StatusItem
                  key={index + status}
                  status={status}
                  index={index}
                  hasBackground={status === 'pending'}
                />
              ))
            ) : (
              <div className="text-s">None</div>
            )}
          </div>
        </>
      )}
    </li>
  );
}

const showPlacement = (placementId: PlacementId) => {
  const el = document.getElementById(placementId);
  if (el) {
    console.log(`Found ${placementId}. Scrolling to it!`);
    el.scrollIntoView({ behavior: 'smooth' });

    el.style.border = '4px solid blue';
    el.style.transition = 'none';

    setTimeout(() => {
      el.style.transition = 'border 0.3s ease';
      el.style.border = '0px solid blue';
    }, 2000);
  }
};

interface StatusItemProps {
  status: string;
  index: number;
  hasBackground: boolean;
}
const StatusItem = ({ status, hasBackground }: StatusItemProps) => (
  <div
    title={hasBackground ? 'Filter was updated here' : status}
    className={classNames('p-2 text-s rounded-4 border', {
      's-bg-warning-subtle': hasBackground,
      's-bg-negative-subtle': status === 'error'
    })}
    key={status}
  >
    {status === 'error' ? 'noFill' : status}
  </div>
);
