// atomConstants.ts

import { $adnTargetingAtom } from '@client/core/atoms/adnTargeting';
import { setInitialPlacementStatusMetrics } from '@client/core/atoms/metrics';
import {
  AdServer,
  AfsConfig,
  AfsPlacement,
  ClientAdPlacement,
  ConsentStatus,
  devToolToggled,
  InitialState,
  setAdvtState
} from '@schibsted-nmp/advertising-shared';
import { $wallpaperConfig } from '@client/core/atoms/wallpaperConfigAtom';

import { $afsAtom } from './afs';
import {
  $config,
  $initialState,
  $ppidAtom,
  getEmptyOrPersistedPPID
} from './config';
import { $gamTargetingAtom } from './gamTargeting';
import {
  $placementsMap,
  $statusSequence,
  addStatusToSequence,
  getPlacementList,
  listenPlacementKeysForValueChange
} from './placements';
import { $unleashFeaturesAtom } from './unleashFeatures';
import { $consentAtom } from './consent';

export enum AtomChannels {
  Podlet = 'advertising-podlet'
}

export enum AtomTopics {
  PlacementsMap = 'placementsMap',
  PPID = 'ppid',
  Glimr = 'glimr',
  Cycle = 'cycle',
  GamTargeting = 'gamTargeting',
  AdnTargeting = 'adnTargeting',
  PrebidLoadedList = 'prebidLoadedList',
  Config = 'config',
  Afs = 'afs',
  PlacementStatusMetrics = 'placementStatusMetrics',
  UnleashFeatures = 'unleashFeatures',
  SearchFilters = 'searchFilters',
  InitialState = 'initialState',
  WaitForClient = 'waitForClient',
  PlacementMetricSequence = 'placementMetricSequence',
  AdStatusSequence = 'adStatusSequence',
  SignificantEvents = 'significantEvents',
  Consent = 'consent'
}

const allAdServers = [AdServer.AFS, AdServer.ADN, AdServer.GAM];
const consentedAdServers = [AdServer.AFS, AdServer.GAM];
const nonConsentedAdServers = [AdServer.ADN];

const allowedAdServersWithConsent = {
  dba: {
    consent: consentedAdServers,
    noConsent: nonConsentedAdServers
  }
};

export const setInitialGlobalAtoms = async (
  initialState: InitialState,
  consent: ConsentStatus
) => {
  $config.set(initialState?.config || {});
  $ppidAtom.set(getEmptyOrPersistedPPID());
  $gamTargetingAtom.set(initialState?.config?.adServer?.gam?.targeting || []);
  $adnTargetingAtom.set(initialState?.config?.adServer?.adn?.kv || []);
  $consentAtom.set(consent);

  const isDba = initialState?.config?.brand === 'dba';
  const allowedAdservers = isDba
    ? allowedAdServersWithConsent.dba[
        consent === ConsentStatus.Accepted ? 'consent' : 'noConsent'
      ]
    : allAdServers;
  const allowedPlacements =
    initialState?.config?.placements.filter((placement) =>
      allowedAdservers.includes(placement.adServer.type)
    ) || [];

  const placementsMap: Record<string, ClientAdPlacement> =
    allowedPlacements.reduce<Record<string, ClientAdPlacement>>(
      (map, placement) => {
        map[`${placement.placementId}`] = {
          ...placement,
          status: 'pending',
          creativeType: '',
          statusSequence: []
        } as unknown as ClientAdPlacement;
        return map;
      },
      {} as Record<string, ClientAdPlacement>
    );

  if (devToolToggled() || initialState?.env === 'local') {
    allowedPlacements.forEach((placement) => {
      listenPlacementKeysForValueChange(
        placement.placementId,
        'status',
        (status, oldStatus) => {
          if (status !== oldStatus) {
            addStatusToSequence(placement.placementId, status);
          }
        }
      );
    });
  }
  $placementsMap.set(placementsMap);

  $afsAtom.set({
    config: initialState?.config?.adServer.afs || ({} as AfsConfig),
    placements: initialState?.config?.placements.filter(
      (placement) => placement.adServer.type === AdServer.AFS
    ) as AfsPlacement[]
  });

  setInitialPlacementStatusMetrics(getPlacementList());
  $unleashFeaturesAtom.set(initialState?.unleashFeatures || {});
  $initialState.set({
    env: initialState?.env,
    baseUrl: initialState?.baseUrl,
    logSessionId: initialState?.logSessionId
  });
};

setAdvtState('state', () => ({
  initialState: $initialState.get(),
  config: $config.get(),
  ppid: $ppidAtom.get(),
  placements: $placementsMap.get(),
  afs: $afsAtom.get(),
  unleashFeatures: $unleashFeaturesAtom.get(),
  gamTargeting: $gamTargetingAtom.get(),
  adnTargeting: $adnTargetingAtom.get(),
  statusSequence: $statusSequence.get()
}));

setAdvtState('setWallpaperConfig', $wallpaperConfig.set);
