import React from 'react';

import { $config } from '@client/core/atoms/config';
import { $consentAtom } from '@client/core/atoms/consent';

// Add this function to get user ID from meta tag
export const getUserId = () => {
  if (typeof window === 'undefined') return '';
  const metaTag = document.querySelector('meta[name="nmp:tracking:login-id"]');
  return metaTag?.getAttribute('content') || 'Not signed in';
};

export const ShortStatus = () => {
  const {
    pageType = '?',
    searchKey = '?',
    deviceType = '?',
    brand = '?',
    vertical = '?',
    subvertical = '?',
    adServer = {}
  } = $config.get() ?? {};

  const consent = $consentAtom.get();

  const configItems = [
    {
      label: 'pageType',
      value:
        pageType === 'result'
          ? `${pageType} ${searchKey?.replace('SEARCH_ID_', '')}`
          : pageType
    },
    { label: 'deviceType', value: deviceType },
    { label: 'brand', value: brand },
    { label: 'vertical', value: `${vertical} ${subvertical}` },
    {
      label: 'user',
      value: `UserId: ${getUserId()}, Consent: ${consent}`
    },
    {
      label: 'Adservers',
      value: Object.keys(adServer)
        .map((key) => key.toUpperCase())
        .join(', ')
    }
  ];

  return (
    <div className="grid grid-cols-3 gap-24 p-24">
      {configItems.map((item, index) => (
        <div
          key={index}
          className="flex flex-col gap-4"
          style={{ textAlign: 'center' }}
        >
          <small className="text-xs s-text-subtle">{item.label}</small>
          <b className="text-s">{item.value}</b>
        </div>
      ))}
    </div>
  );
};
