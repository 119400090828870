/** Based on {@link https://github.schibsted.io/spt-privacy/sourcepoint/blob/master/src/browser/api/index.js} */
export namespace TcfHelperMock {
  export type ConsentState = 'Consented' | 'NonConsented';

  export namespace ConsentState {
    let currentValue: ConsentState = 'Consented';

    export function get() {
      return currentValue;
    }

    export function set(consentState: ConsentState) {
      currentValue = consentState;
    }
  }

  export namespace Window {
    export function inject() {
      if (window._tcf_) return;

      const consentState =
        sessionStorage.getItem('ADVT_DEV_TOOL_consentState') === 'NonConsented'
          ? 'NonConsented'
          : 'Consented';

      ConsentState.set(consentState);

      window._tcf_ = {
        getConsentedToAllSync() {
          return ConsentState.get() === 'Consented';
        },

        isConsentedToAll(callback) {
          callback(ConsentState.get() === 'Consented');
        }
      };
    }
  }
}
