import React from 'react';

import { $config, $initialState } from '@client/core/atoms/config';
import { getAllFeatureStatuses } from '@client/core/atoms/unleashFeatures';

type FeatureFlagListProps = {
  title: string;
  features: string[];
  bgColor: string;
  getUnleashUrl: () => string;
};

const FeatureFlagList = ({
  title,
  features,
  bgColor,
  getUnleashUrl
}: FeatureFlagListProps) => (
  <div>
    <h6 className="font-semibold mb-2">{title}</h6>
    {features.length === 0 ? (
      <div className="text-gray-500">No {title.toLowerCase()}</div>
    ) : (
      <div className="flex flex-col gap-1">
        {features.map((feature) => (
          <a
            href={`${getUnleashUrl()}/strategies/advertising-podlet.${feature}`}
            key={feature}
            target="_blank"
            rel="noreferrer"
            className={`${bgColor} px-2 py-1 rounded-md w-fit`}
          >
            {feature}
          </a>
        ))}
      </div>
    )}
  </div>
);

export function FeatureFlagTab() {
  const { brand } = $config.get();
  const { env } = $initialState.get();
  const allFeatureFlags = getAllFeatureStatuses();
  const enabledFeatureFlags = Object.keys(allFeatureFlags).filter(
    (feature) => allFeatureFlags[feature]
  );
  const disabledFeatureFlags = Object.keys(allFeatureFlags).filter(
    (feature) => !allFeatureFlags[feature]
  );

  function getUnleashUrl(): string {
    let unleashDevDomain = '';
    const _env = env === 'prod' ? 'prod' : 'dev';
    switch (brand) {
      case 'dba': {
        unleashDevDomain = _env === 'dev' ? 'dev.dba.dk' : 'dba.dk';
        break;
      }
      case 'finn': {
        unleashDevDomain = `${_env}.finn.no`;
        break;
      }
      case 'tori': {
        if (_env === 'dev') {
          unleashDevDomain = 'toripg.rc.nmp.finn.no';
        } else {
          unleashDevDomain = 'tori.fi';
        }
        break;
      }
      default: {
        unleashDevDomain = 'prod.finn.no';
        break;
      }
    }

    return `https://unleash.svc.${unleashDevDomain}/#/features`;
  }

  return (
    <div className="flex flex-col gap-4">
      <h5>Feature Flags</h5>
      <h6>Unleash URL:</h6>
      <a href={getUnleashUrl()}>{getUnleashUrl()}</a>
      <div className="flex flex-col gap-4">
        <FeatureFlagList
          title="Enabled Features:"
          features={enabledFeatureFlags}
          bgColor="bg-green-100"
          getUnleashUrl={getUnleashUrl}
        />
        <FeatureFlagList
          title="Disabled Features:"
          features={disabledFeatureFlags}
          bgColor="bg-red-100"
          getUnleashUrl={getUnleashUrl}
        />
      </div>
    </div>
  );
}

FeatureFlagTab.friendlyName = 'Flags' as const;
