const win = typeof window !== 'undefined' ? window : ({} as Window);

// localStorage access throws exceptions in Android Chrome when cookies
// are disabled and when in private mode in Safari, hence all try/catch blocks
const hasStorageCapability = (() => {
  try {
    const test = 'test';
    win.localStorage.setItem(test, test);
    win.localStorage.removeItem(test);

    return true;
  } catch {
    return false;
  }
})();

function isTSExpired(timestamp, maxAge) {
  const nowMS = new Date().getTime();

  return timestamp + maxAge < nowMS;
}

function persistValue(key, value, timestamp) {
  if (hasStorageCapability && value !== undefined && value !== null) {
    const persistMe = {
      data: value,
      timestamp: timestamp || new Date().getTime()
    };
    win.localStorage.setItem(key, JSON.stringify(persistMe));

    return true;
  }

  return false;
}

function clearValue(key) {
  if (hasStorageCapability) {
    win.localStorage.removeItem(key);
  }
}

function _getParsedValue(key) {
  const storedValue = win.localStorage.getItem(key);
  if (!storedValue) return null;
  try {
    return JSON.parse(storedValue);
  } catch {
    if (win) {
      console.log(
        `Unable to parse stored value for key ${key}. Clearing value.`,
        storedValue
      );
    }

    clearValue(key);
  }

  return null;
}

const FIVE_MINUTES_IN_MS = 5 * 60 * 1000;

function readValue(key, maxAgeMillis = FIVE_MINUTES_IN_MS) {
  if (!hasStorageCapability) {
    return null;
  }

  const persisted = _getParsedValue(key);

  if (persisted && persisted.data) {
    if (persisted.timestamp) {
      return isTSExpired(persisted.timestamp, maxAgeMillis)
        ? null
        : persisted.data;
    }

    return persisted.data;
  }

  return null;
}

const setItem = (key, item) => {
  if (hasStorageCapability) {
    win.localStorage.setItem(key, JSON.stringify(item));
  }
};

const getItem = (item) => {
  const retrievedItem = window.localStorage.getItem(item);
  if (hasStorageCapability && retrievedItem) {
    return JSON.parse(retrievedItem);
  }

  return null;
};

const localStorage: {
  readValue: (key: string, maxAgeMillis?: number) => any;
  setItem: (key: string, item: any) => void;
  getItem: (item: any) => any;
  persistValue: (key: string, value: any, timestamp?: number) => boolean;
  clearValue: (key: string) => void;
  hasStorageCapability: boolean;
} = {
  persistValue,
  readValue,
  clearValue,
  hasStorageCapability,
  setItem,
  getItem
};
export default localStorage;
