import type {
  PubAdsService,
  WithUnknownProperties
} from '@schibsted-nmp/advertising-shared';

import type { Pbjs } from './Pbjs';

export type Api = Api.Uninitialized | Api.Initialized | Api.Loaded;

export namespace Api {
  export type Uninitialized = { cmd?: Array<VoidFunction> } | undefined;

  export type Initialized = Required<NonNullable<Api.Uninitialized>>;

  /**
   * Available globally via `window.relevantDigital` after initialization. More
   * methods are added after the library is loaded. Only the ones we use are
   * defined in this type, but there are more available.
   *
   * @see https://help.relevant-digital.com/knowledge/hb-manager#javascript-api */
  export type Loaded = WithUnknownProperties<{
    readonly cmd: Array<VoidFunction>;
    addPrebidConfig(prebidConfig: Pbjs.Common.Config): void;
    loadPrebid(options: LoadPrebidOptions): void;

    addAuctionCallbacks(
      options: AddAuctionCallbacksOptions,
      ...parameters: Array<unknown>
    ): void;

    getConfigs(): ReadonlyArray<Config>;
  }>;

  export namespace Reference {
    export const globalAccessor = 'relevantDigital' as const;

    let resolved: Loaded | null = null;

    export async function resolve(...abortSignals: ReadonlyArray<AbortSignal>) {
      for (const abortSignal of abortSignals) abortSignal.throwIfAborted();

      if (resolved) return resolved;

      const { promise, resolve, reject } = Promise.withResolvers<Loaded>();

      try {
        const globalRef = prepare();

        for (const abortSignal of abortSignals) {
          abortSignal.addEventListener('abort', reject);
        }

        globalRef.cmd.push(() => {
          try {
            for (const abortSignal of abortSignals) {
              abortSignal.throwIfAborted();
            }

            if (!('loadPrebid' in globalRef)) {
              throw new Error(
                `Failed resolving reference 'window.${globalAccessor}'`
              );
            }

            resolve((resolved ??= globalRef));
          } catch (error) {
            reject(error);
          }
        });
      } catch (error) {
        reject(error);
      } finally {
        for (const abortSignal of abortSignals) {
          abortSignal.removeEventListener('abort', reject);
        }
      }

      return await promise;
    }

    export function prepare() {
      const api: Uninitialized = (window[globalAccessor] ??= {});
      api.cmd ??= [];

      // TODO: after enabling exactOptionalPropertyTypes, remove unnecessary cast:
      return api as Api.Initialized | Api.Loaded;
    }
  }

  export async function execute<TReturn>(
    executor: execute.Executor<TReturn>,
    ...abortSignals: ReadonlyArray<AbortSignal>
  ): Promise<TReturn> {
    for (const abortSignal of abortSignals) abortSignal.throwIfAborted();

    const api = await Reference.resolve(...abortSignals);
    const { promise, resolve, reject } = Promise.withResolvers<TReturn>();

    api.cmd.push(() => {
      try {
        for (const abortSignal of abortSignals) abortSignal?.throwIfAborted();
        resolve(executor(api, ...abortSignals));
      } catch (error) {
        reject(error);
      }
    });

    return await promise;
  }

  export namespace execute {
    export type Executor<TReturn> =
      TReturn extends Promise<unknown>
        ? never
        : (api: Loaded, ...abortSignals: ReadonlyArray<AbortSignal>) => TReturn;
  }

  export namespace Common {
    export type Auction = WithUnknownProperties<{
      readonly auctionId: string;
      readonly adservers: ReadonlyArray<Adserver>;
      readonly pbjs: Pbjs;
    }>;

    export namespace Auction {
      export type CallbackOptions = {
        onBeforeAdRequest?(parameters: CallbackOptions.Parameters): void;
      };

      export namespace CallbackOptions {
        export type Parameters = WithUnknownProperties<{
          readonly auction: Auction;
        }>;
      }
    }

    export type Adserver = WithUnknownProperties<{
      readonly type: Adserver.Type;
    }>;

    export namespace Adserver {
      export type Type = 'GamAdserver' | 'AdnuntiusAdserver';
    }
  }

  export type LoadPrebidOptions = {
    configId?: string;
    manageAdserver?: boolean;
    collapseEmptyDivs?: boolean;
    noGpt?: boolean;
    collapseBeforeAdFetch?: boolean;
    noSlotReload?: boolean;
    allowedDivIds?: Array<string>;
    googletagCalls?: LoadPrebidOptions.GoogletagCalls;
  } & Common.Auction.CallbackOptions;

  export namespace LoadPrebidOptions {
    export type GoogletagCalls = WithUnknownProperties<
      Pick<PubAdsService, 'refresh'>
    >;
  }

  export type AddAuctionCallbacksOptions = Common.Auction.CallbackOptions;

  export type Config = WithUnknownProperties<{
    readonly configId: string;
    readonly adUnits: ReadonlyArray<Config.AdUnit>;
  }>;

  export namespace Config {
    export type AdUnit = WithUnknownProperties<{
      readonly placementId: string;
      readonly gamPath?: string;
    }>;
  }

  export type Window = { [Reference.globalAccessor]?: Api };
}

declare global {
  interface Window extends Api.Window {}
}
