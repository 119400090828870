import { atom } from '@podium/store';
import {
  PlacementId,
  PlacementStatusesEnum,
  PlacementStatusMetric
} from '@schibsted-nmp/advertising-shared';
import { ClientAdPlacement } from '@schibsted-nmp/advertising-shared/src/types/client';

import { getPlacementById, getPlacementList } from './placements';

import { AtomChannels, AtomTopics } from '.';

export const $placementStatusMetrics = atom<PlacementStatusMetric[]>(
  AtomChannels.Podlet,
  AtomTopics.PlacementStatusMetrics,
  []
);

export const $lifecycleEvents = atom<string[]>(
  AtomChannels.Podlet,
  AtomTopics.SignificantEvents,
  []
);

export function addLifecycleEvent(event: string) {
  $lifecycleEvents.set([...$lifecycleEvents.get(), event]);
}

export function updateMetricByKey(
  placementId: PlacementId,
  key: PlacementStatusesEnum
) {
  const oldMetrics = $placementStatusMetrics.get();
  let placementExists = false;
  const updatedMetrics: PlacementStatusMetric[] = oldMetrics.map(
    (oldMetric) => {
      if (oldMetric.placementId === placementId) {
        placementExists = true;
        // Only add the key if it doesn't already exist in statuses
        if (!oldMetric.statuses.includes(key)) {
          return {
            ...oldMetric,
            statuses: [...oldMetric.statuses, key]
          };
        }
      }
      return oldMetric;
    }
  );

  if (!placementExists) {
    updatedMetrics.push({
      placementId,
      adServer: getPlacementById(placementId)?.adServer.type,
      statuses: [key]
    });
  }

  $placementStatusMetrics.set(updatedMetrics);
}

export function setInitialPlacementStatusMetrics(
  placements: ClientAdPlacement[]
) {
  $placementStatusMetrics.set([]);
  placements.forEach((placement) => {
    updateMetricByKey(placement.placementId, PlacementStatusesEnum.InConfig);
  });
}

export function updateMetricsForAllPlacements(status: PlacementStatusesEnum) {
  const currentMetrics = $placementStatusMetrics.get();
  const allPlacements = getPlacementList();

  const updatedMetrics = allPlacements.map((placement) => {
    const existingMetric = currentMetrics.find(
      (metric) => metric.placementId === placement.placementId
    );

    if (existingMetric) {
      return {
        ...existingMetric,
        statuses: [...existingMetric.statuses, status]
      };
    }
    return {
      placementId: placement.placementId,
      adServer: placement.adServer.type,
      statuses: [status]
    };
  });

  $placementStatusMetrics.set(updatedMetrics);
}
