import { UNLEASH_FEATURE_NAME } from '@schibsted-nmp/advertising-shared';
import type { Script } from '@client/core/utils/Script';
import { Scripts as RelevantYieldScripts } from '@client/core/relevantDigital/Scripts';
import { isFeatureEnabled } from '@client/core/atoms/unleashFeatures';

export namespace Scripts {
  export function* get(): Generator<Script, void, void> {
    if (!isFeatureEnabled(UNLEASH_FEATURE_NAME.enableGam)) return;

    yield {
      name: 'gam',
      src: 'https://securepubads.g.doubleclick.net/tag/js/gpt.js',
      defer: true
    };

    yield* RelevantYieldScripts.get();
  }
}
