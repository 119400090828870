if (typeof Promise.withResolvers === 'undefined') {
  Promise.withResolvers = <T>(): PromiseWithResolvers<T> => {
    let resolve: PromiseWithResolvers<T>['resolve'];
    let reject: PromiseWithResolvers<T>['reject'];

    const promise = new Promise<T>((...args) => {
      [resolve, reject] = args;
    });

    return { promise, resolve, reject };
  };
}

export {};
