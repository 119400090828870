import React from 'react';

import { $config } from '@client/core/atoms/config';
import * as RelevantDigital from '@client/core/relevantDigital';
import { DeviceTypeEnum } from '@schibsted-nmp/advertising-shared';

export function RelevantTab() {
  const [filteredPlacements, setFilteredPlacements] =
    React.useState<ReadonlyArray<RelevantDigital.Api.Config.AdUnit> | null>(
      null
    );

  const [generalConfig, setGeneralConfig] =
    React.useState<RelevantTab.GeneralConfig | null>(null);

  const [error, setError] = React.useState<string | null>(null);

  React.useEffect(() => {
    if (typeof window.relevantDigital === 'undefined') {
      setError('RelevantDigital is not defined.');
      return;
    }

    const deviceTypeMapping = {
      [DeviceTypeEnum.DESKTOP]: 'wde',
      [DeviceTypeEnum.TABLET]: 'wde',
      [DeviceTypeEnum.MOBILE]: 'wph'
    } as const satisfies Record<DeviceTypeEnum, 'wde' | 'wph'>;

    const config = $config.get();
    const deviceType = deviceTypeMapping[config?.deviceType] || 'unknownDevice';
    const pageType = config?.pageType || 'unknownPage';

    async function fetchConfigs() {
      try {
        const configs = await RelevantDigital.Api.execute((api) =>
          api.getConfigs()
        );

        const pageConfig = configs.find(({ configId }) => configId);

        if (!pageConfig?.adUnits) {
          setError('No placements found for the current page.');
          return;
        }

        const { adUnits, ...generalConfig } = pageConfig;
        setGeneralConfig(generalConfig);

        const filtered = adUnits.filter(
          ({ gamPath }) =>
            gamPath?.includes(deviceType) && gamPath.includes(pageType)
        );

        if (filtered.length === 0) {
          setError('No matching placements found.');
          return;
        }

        setFilteredPlacements(filtered);
      } catch {
        setError('Failed to fetch configurations.');
      }
    }

    fetchConfigs();
  }, []);

  if (error) return <p>Error: {error}</p>;

  return (
    <div>
      {generalConfig && (
        <div>
          <h4>General Configurations:</h4>
          <RelevantTab.ObjectExplorer data={generalConfig} />
        </div>
      )}

      <div>
        <h4>Placements:</h4>
        {filteredPlacements?.length ? (
          filteredPlacements.map((placement, index) => (
            <div key={index}>
              <h5>
                Placement ID: {placement.placementId} | GAM Path:{' '}
                {placement.gamPath}
              </h5>
              <RelevantTab.ObjectExplorer data={placement} />
            </div>
          ))
        ) : (
          <p>Loading relevant placements...</p>
        )}
      </div>
    </div>
  );
}

export namespace RelevantTab {
  export const friendlyName = 'Relevant' as const;

  export type GeneralConfig = Omit<RelevantDigital.Api.Config, 'adUnits'>;

  export function ObjectExplorer({
    data,
    depth = 0,
    keyName = '',
    inPlacement = false
  }) {
    const keysToClose = [
      'placementTypesById',
      'bannerAdsIds',
      'instreamAdsIds',
      'userSync',
      'allAdsIds'
    ];

    const [isOpen, setIsOpen] = React.useState(
      !keysToClose.includes(keyName) || inPlacement
    );

    if (typeof data !== 'object' || data === null) {
      return <span>{JSON.stringify(data)}</span>;
    }

    const isArray = Array.isArray(data);
    const isEmpty = Object.keys(data).length === 0;

    return (
      <div className="text-s" style={{ marginLeft: `${depth * 4}px` }}>
        <span onClick={() => setIsOpen(!isOpen)} style={{ cursor: 'pointer' }}>
          {isOpen ? '▼' : '▶'}
          {isArray ? '[' : '{'}
          {isEmpty && (isArray ? ']' : '}')}
        </span>

        {isOpen && !isEmpty && (
          <>
            <div>
              {Object.entries(data).map(([key, value]) => (
                <div key={key}>
                  <span>{key}: </span>
                  <ObjectExplorer
                    data={value}
                    depth={depth + 1}
                    keyName={key}
                    inPlacement={inPlacement || keyName === 'placements'}
                  />
                </div>
              ))}
            </div>
            <div style={{ marginLeft: `${depth * 4}px` }}>
              {isArray ? ']' : '}'}
            </div>
          </>
        )}
      </div>
    );
  }
}
