import type {
  EntryValue,
  GeneratorYieldValue
} from '@schibsted-nmp/advertising-shared';

import type { PrebidRequester } from './PrebidRequester';

export namespace CustomSettings {
  export function* generate(
    sspCustomSettings: PrebidRequester['siteData']['sspCustomSettings'],
    byBidderInfo: PrebidRequester['byBidderInfo']
  ) {
    if (!sspCustomSettings) return;

    const relevantYieldSspIdToBidderInfoMap = new Map(
      Object.entries(byBidderInfo).map(
        ([bidderCode, bidderInfo]) =>
          [bidderInfo.id, { bidderCode, ...bidderInfo }] as const
      )
    );

    for (const [relevantYieldSspId, customSettings] of Object.entries(
      sspCustomSettings
    )) {
      const { bidderCode } =
        relevantYieldSspIdToBidderInfoMap.get(relevantYieldSspId) ?? {};

      if (!bidderCode) continue;

      const { bidderCodeOrAlias, ...settings } = customSettings;
      yield [bidderCode, settings] as const;
    }
  }

  export function from(
    sspCustomSettings: PrebidRequester['siteData']['sspCustomSettings'],
    byBidderInfo: PrebidRequester['byBidderInfo']
  ): Map {
    return new Map(generate(sspCustomSettings, byBidderInfo));
  }

  export type Value = EntryValue<
    GeneratorYieldValue<ReturnType<typeof generate>>
  >;

  export type Map<T extends Value = Value> = ReadonlyMap<string, T>;
}
