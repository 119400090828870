import {
  AdnAdUnit,
  debugLog,
  PlacementId,
  PlacementStatusesEnum
} from '@schibsted-nmp/advertising-shared';
import { updateMetricByKey } from '@client/core/atoms/metrics';
import { updatePlacementKeyValueById } from '@client/core/atoms/placements';

export const setupAdnEventListeners = (adUnits: AdnAdUnit[]) => {
  adUnits.forEach((adUnit) => {
    updatePlacementKeyValueById(
      adUnit.targetId as PlacementId,
      'status',
      'requested'
    );
  });

  window.adn.requestData({
    adUnits,
    onSuccess: ({ responseJSON }) => {
      responseJSON?.adUnits.forEach((adUnit) => {
        const placementId = adUnit.targetId as PlacementId;
        const hasFilled = adUnit.matchedAdCount > 0;

        if (hasFilled) {
          debugLog(`Adnuntius ad loaded for placement: ${placementId}`, adUnit);
          updatePlacementKeyValueById(placementId, 'status', 'loaded');
          updateMetricByKey(placementId, PlacementStatusesEnum.AdLoaded);
        } else {
          debugLog(`Adnuntius ad did not fill for placement: ${placementId}`);
          updateMetricByKey(placementId, PlacementStatusesEnum.AdNoFill);
          updatePlacementKeyValueById(placementId, 'status', 'error');
        }
      });
    }
  });
};
