import { PlacementId } from './types/adPlacement';
import { AdServer, DeviceType } from './types/api/adConfig';
import { PageType, Vertical } from './types/layouts';

export enum PlacementStatusesEnum {
  InConfig = 'in_config',
  NotInConfig = 'not_in_config',
  AdRequested = 'ad_requested',
  AdLoaded = 'ad_loaded',
  AdNoFill = 'ad_no_fill',
  SlotCreated = 'slot_created',
  HasElement = 'element_in_dom',
  NoElement = 'no_element_in_dom',
  Viewed = 'ad_viewed',
  Refresh = 'refresh',
  CollapsedOnCollision = 'collapsed_on_collision'
}

export type PlacementStatusMetric = {
  placementId: PlacementId;
  adServer?: AdServer;
  statuses: PlacementStatusesEnum[];
};

export type PlacementStatusMetricsBody = {
  deviceType: DeviceType;
  pageType: PageType;
  vertical: Vertical;
  subvertical: string;
  adServer: AdServer;
  placementStatuses: PlacementStatusMetric[];
};

export type ErrorMetricsBody = {
  deviceType: DeviceType;
  pageType: PageType;
  vertical: Vertical;
  subvertical: string;
  errorMessage: string;
  logSessionId: string;
};
