// Verticals and SubVerticals are not decided upon yet, don't use
export type FinnLegendaryVertical =
  | 'bap'
  | 'car'
  | 'realestate'
  | 'job'
  | 'mc'
  | 'boat'
  | 'b2b'
  | 'travel';

export type NmpVertical = 'mobility' | 'recommerce' | 'front';

export type Vertical = FinnLegendaryVertical | NmpVertical;

export type Brand =
  | 'tori'
  | 'finn'
  | 'blocket'
  | 'dba'
  | 'bilbasen'
  | 'oikotie'
  | 'rakentaja';

export type PageType = 'front' | 'section' | 'result' | 'object';

// Define enums based on the types
export enum VerticalEnum {
  BAP = 'bap',
  CAR = 'car',
  REALESTATE = 'realestate',
  JOBS = 'jobs',
  JOB = 'job',
  MC = 'mc',
  BOAT = 'boat',
  B2B = 'b2b',
  TRAVEL = 'travel',
  MOBILITY = 'mobility',
  RECOMMERCE = 'recommerce',
  ATV = 'atv',
  SNOWMOBILE = 'snowmobile',
  SCOOTER = 'scooter',
  FRONT = 'front'
}

export enum DeviceTypeEnum {
  DESKTOP = 'desktop',
  MOBILE = 'mobile',
  TABLET = 'tablet'
}

export enum PageTypeEnum {
  FRONT = 'front',
  SECTION = 'section',
  RESULT = 'result',
  OBJECT = 'object'
}

export enum BrandEnum {
  TORI = 'tori',
  FINN = 'finn',
  BLOCKET = 'blocket',
  DBA = 'dba',
  BILBASEN = 'bilbasen',
  OIKOTIE = 'oikotie',
  RAKENTAJA = 'rakentaja'
}
