import React from 'react';

import { TogglePanelButton } from '@client/dev/components/TogglePanelButton';
import { DevPanelContainer } from '@client/dev/components/DevPanelContainer';
import { Tab } from '@client/dev/components/Tab';
import { ShortStatus } from '@client/dev/components/ShortStatus';
import { PlacementsTab } from '@client/dev/components/Tabs/PlacementsTab';
import { FeatureFlagTab } from '@client/dev/components/Tabs/FeatureFlagTab';
import { SignificantEventsTab } from '@client/dev/components/Tabs/SignificantEventsTab';
import { ErrorTab } from '@client/dev/components/Tabs/ErrorTab';
import { DataTab } from '@client/dev/components/Tabs/DataTab';
import { ToolsTab } from '@client/dev/components/Tabs/ToolsTab';
import { RelevantTab } from '@client/dev/components/Tabs/RelevantTab';
import { ConsentTab } from '@client/dev/components/Tabs/ConsentTab';
import localStorage from '@client/core/utils/localStorage';

export function DevPanel() {
  const [isOpen, setIsOpen] = React.useState<boolean>(
    () => localStorage.getItem('ADVT_DEV_TOOL_isOpen') === 'true'
  );

  React.useEffect(() => {
    localStorage.setItem('ADVT_DEV_TOOL_isOpen', String(isOpen));
  }, [isOpen]);

  React.useEffect(() => {
    const savedIsOpen = localStorage.getItem('ADVT_DEV_TOOL_isOpen');
    if (savedIsOpen !== null) {
      setIsOpen(savedIsOpen === 'true');
    }
  }, []);

  const [SelectedTabComponent, setSelectedTabComponent] =
    DevPanel.TabManager.use();

  return (
    <DevPanelContainer isOpen={isOpen}>
      <TogglePanelButton isOpen={isOpen} setIsOpen={setIsOpen} />

      <div className="s-bg-selected pt-24">
        <ShortStatus />

        <Tab.Group<DevPanel.TabManager.TabComponent>
          TabComponents={DevPanel.TabManager.TabComponent.values}
          SelectedTabComponent={SelectedTabComponent}
          onSelect={setSelectedTabComponent}
        />
      </div>

      <SelectedTabComponent />
    </DevPanelContainer>
  );
}

export namespace DevPanel.TabManager {
  export type TabComponent = (typeof TabComponent.values)[number];

  export namespace TabComponent {
    export const values = [
      PlacementsTab,
      FeatureFlagTab,
      DataTab,
      RelevantTab,
      ErrorTab,
      SignificantEventsTab,
      ToolsTab,
      ConsentTab
    ] as const satisfies ReadonlyArray<Tab>;
  }

  export function use() {
    const [SelectedTabComponent, _setSelectedTabComponent] = React.useState(
      () => Storage.readOrDefault(PlacementsTab)
    );

    const setSelectedTabComponent = React.useCallback(
      (TabComponent: DevPanel.TabManager.TabComponent) => {
        _setSelectedTabComponent(() => TabComponent);
      },
      [_setSelectedTabComponent]
    );

    Storage.use(SelectedTabComponent, setSelectedTabComponent);

    return [SelectedTabComponent, setSelectedTabComponent] as const;
  }

  export type FriendlyName = (typeof FriendlyName.values)[number];

  export namespace FriendlyName {
    export const values = [
      ...TabComponent.values.map(({ friendlyName }) => friendlyName)
    ] as const;

    export function is(friendlyName: string): friendlyName is FriendlyName {
      return TabComponent.values.some(
        (entry) => entry.friendlyName === friendlyName
      );
    }

    export function toTabComponent(
      friendlyName: FriendlyName
    ): TabComponent | null {
      return (
        TabComponent.values.find(
          (entry) => entry.friendlyName === friendlyName
        ) ?? null
      );
    }
  }

  export namespace Storage {
    export const key = 'ADVT_DEV_TOOL_selectedTab' as const;

    export function use(
      SelectedTabComponent: TabComponent,
      select: (TabComponent: DevPanel.TabManager.TabComponent) => void
    ) {
      React.useEffect(() => {
        const SavedTabComponent = DevPanel.TabManager.Storage.readOrDefault();
        if (SavedTabComponent) select(SavedTabComponent);
      }, [select]);

      React.useEffect(() => {
        DevPanel.TabManager.Storage.write(SelectedTabComponent);
      }, [SelectedTabComponent]);
    }

    export function readOrDefault<T extends TabComponent | null>(
      defaultValue = null as T
    ): TabComponent | T {
      try {
        const friendlyName = localStorage.getItem(Storage.key);

        if (!friendlyName) return defaultValue;
        if (!FriendlyName.is(friendlyName)) return defaultValue;

        return FriendlyName.toTabComponent(friendlyName) ?? defaultValue;
      } catch {
        return defaultValue;
      }
    }

    export function write(TabComponent: TabComponent) {
      localStorage.setItem(Storage.key, TabComponent.friendlyName);
    }
  }
}
