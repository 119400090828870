export function ensurePathStartsWithSlash(path: string): string {
  if (!path.startsWith('/')) {
    return `/${path}`;
  }
  return path;
}

export function removeWallpaperDiv() {
  const wallpaperDiv =
    typeof document !== 'undefined' &&
    document.getElementById('wallpaper-container');
  if (wallpaperDiv) {
    wallpaperDiv.remove();
  }
}
