import { AdnConfig, AdnPlacement } from '../adServers/adn';
import { AfsConfig, AfsPlacement } from '../adServers/afs';
import { GamConfig, GamPlacement } from '../adServers/gam';
import { Brand, PageType, Vertical } from '../layouts';
import { SearchKey } from '../layouts/search';
import { ConsentStatus } from './api';

export interface AdConfig {
  consent?: ConsentStatus;
  brand: Brand;
  deviceType: DeviceType;
  vertical: Vertical;
  subvertical?: string | null;
  pageType: PageType;
  adServer: AdConfigServer;
  placements: AdConfigPlacement[];
  searchKey?: SearchKey;
}

export type AdConfigPlacement = AfsPlacement | GamPlacement | AdnPlacement;

export enum AdServer {
  AFS = 'afs',
  GAM = 'gam',
  ADN = 'adn',
  SponsoredContent = 'sponsored-content'
}

export interface AdConfigServer {
  gam?: GamConfig;
  afs?: AfsConfig;
  adn?: AdnConfig;
}

export interface AdConfigIntermingle {
  list: number | null;
  grid: number | null;
}

export type Consents = {
  source: string;
  advertising: ConsentStatus;
  analytics: ConsentStatus;
  marketing: ConsentStatus;
  personalisation: ConsentStatus;
};

export type DeviceType = 'desktop' | 'mobile' | 'tablet';
// Ad Server supports "phone" instead of "mobile", therefore:
export type AdConfigDevice = 'phone' | 'desktop';

export type PermissionValue = '1' | '0' | null;

export type CMPCategory =
  | 'CMP:advertising'
  | 'CMP:analytics'
  | 'CMP:marketing'
  | 'CMP:personalisation';
