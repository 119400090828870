import { Script } from '@client/core/utils/Script';

export namespace Scripts {
  export function* get(): Generator<Script, void, void> {
    yield {
      name: 'pulse',
      src: 'https://sdk-cdn.pulse.m10s.io/versioned/3.0/pulse-modern.min.js'
    };

    yield {
      name: 'pulse-init',
      src: 'https://assets.finn.no/pkg/@finn-no/finn-pulse-init/v1/finn-pulse-init.min.js'
    };
  }
}
