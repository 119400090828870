import { Script } from '@client/core/utils/Script';

export namespace Scripts {
  export function* get(): Generator<Script, void, void> {
    yield {
      name: 'cogwheel',
      src: 'https://cogwheel.inventory.schibsted.io/prod/latest/gdpr-bundle.js',
      defer: true
    };
  }
}
