import { AdnPlacement } from './types/adServers/adn';
import { AfsPlacement } from './types/adServers/afs';
import { GamPlacement } from './types/adServers/gam';
import { AdConfigPlacement, AdServer } from './types/api/adConfig';

export function filterAfsPlacements(
  placements: AdConfigPlacement[]
): AfsPlacement[] {
  return Array.isArray(placements)
    ? (placements.filter(
        (placement) => placement.adServer.type === AdServer.AFS
      ) as AfsPlacement[])
    : [];
}

export function filterGamPlacements(
  placements: AdConfigPlacement[]
): GamPlacement[] {
  return Array.isArray(placements)
    ? (placements.filter(
        (placement) => placement.adServer.type === AdServer.GAM
      ) as GamPlacement[])
    : [];
}

export function filterAdnPlacements(
  placements: AdConfigPlacement[]
): AdnPlacement[] {
  return Array.isArray(placements)
    ? (placements.filter(
        (placement) => placement.adServer.type === AdServer.ADN
      ) as AdnPlacement[])
    : [];
}
