// Add your new Unleash Keys here without the 'advertising-podlet.' prefix
const UNLEASH_FEATURE_NAMES = [
  'killswitch',
  'disableAfsPlacements',
  'enableGam',
  'enableGamTestCampaign',
  'enableAdnTestCampaign',
  'enableAdnuntius',
  'disableAdvertisingConfigApi',
  'enableGamPrebid',
  'enableBrandMetricsScript',
  'enableGamLazyLoading',
  'overrideAfsPubId'
] as const;

export type UnleashFeatureName = (typeof UNLEASH_FEATURE_NAMES)[number];
export function getUnleashFeatureWithPodletPrefix(
  nameId: UnleashFeatureName
): string {
  return `advertising-podlet.${nameId}`;
}
export type UnleashKeys = (typeof UNLEASH_FEATURE_NAMES)[number];
type UnleashKeyObject = { [K in UnleashKeys]: K };

export const UNLEASH_FEATURE_NAME: UnleashKeyObject =
  UNLEASH_FEATURE_NAMES.reduce<UnleashKeyObject>(
    (acc: UnleashKeyObject, key: UnleashKeys) => {
      // @ts-ignore - TS doesn't like the dynamic key
      acc[key] = key;
      return acc;
    },
    {} as UnleashKeyObject
  );

export const UNLEASH_FEATURE_ARRAY: UnleashKeys[] =
  Object.values(UNLEASH_FEATURE_NAME);
