import {
  ConsentStatus,
  debugLog,
  type InitialState
} from '@schibsted-nmp/advertising-shared';
import type { Script } from '@client/core/utils/Script';
import { Scripts as AdnamiScripts } from '@client/core/utils/Adnami/Scripts';
import { Scripts as BrandMetricsScripts } from '@client/core/utils/BrandMetrics/Scripts';
import { Scripts as CogwheelScripts } from '@client/core/utils/Cogwheel/Scripts';
import { Scripts as PulseScripts } from '@client/core/utils/pulse/Scripts';
import { Scripts as AdManagerScripts } from '@client/adManager/Scripts';
import { Scripts as AdnuntiusScripts } from '@client/adnuntius/Scripts';

export namespace Scripts {
  export function* get(
    {
      config: {
        adServer: { gam, adn },
        brand,
        vertical
      },
      env
    }: InitialState,
    consent: ConsentStatus
  ): Generator<Script, void, void> {
    // Default to recommerce vertical when an empty string is provided:
    vertical ||= 'recommerce';

    if (env === 'local') yield* PulseScripts.get();

    if (consent === ConsentStatus.Accepted) {
      yield* BrandMetricsScripts.get(brand);
    }
    yield* CogwheelScripts.get();

    if (gam ?? adn) yield* AdnamiScripts.get(brand);
    if (gam) yield* AdManagerScripts.get();
    if (adn) yield* AdnuntiusScripts.get();
  }

  export function* load(
    initialState: InitialState,
    consent: ConsentStatus
  ): Generator<Promise<Event>, void, void> {
    for (const script of get(initialState, consent))
      yield injectIntoDom(script);
  }

  export async function injectIntoDom({
    name,
    src,
    defer,
    crossOrigin
  }: Script) {
    let resolve: (event: Event) => void;
    let reject: (reason?: unknown) => void;

    const promise = new Promise<Event>((...args) => {
      [resolve, reject] = args;
    });

    const script = document.createElement('script');

    Object.assign(script, {
      src,
      type: 'text/javascript',
      async: true,

      ...(defer && { defer }),
      ...(crossOrigin && { crossOrigin }),

      onload(event) {
        debugLog(`${name} loaded from advertising-podlet`, event);
        resolve(event);
      },

      onerror(error) {
        debugLog(`Failed to load ${name} from advertising-podlet`, error);
        reject(error);
      }
    } satisfies Partial<typeof script>);

    document.head.appendChild(script);

    return await promise;
  }
}
