import { debounce } from 'throttle-debounce';

import { $waitForClient } from '@client/core/atoms/config';
import { addLifecycleEvent } from '@client/core/atoms/metrics';
import { debouncedSendMetrics } from '@client/core/services/metrics';
import { AdnAdUnit } from '@schibsted-nmp/advertising-shared';
import { setupAdnEventListeners } from '@client/adnuntius/AdUnit/setupAdnEventListeners';

let pendingSlots: AdnAdUnit[] = [];

const loadBatchedAllSlots = (adUnits: AdnAdUnit[]) => {
  window.adn.calls.push(() => {
    window.adn.request({
      adUnits
    });
  });
  setupAdnEventListeners(adUnits);
};

// Debounce function that accumulates slots and refreshes them after 200ms
const debounceLoadAd = debounce(
  200,
  () => {
    const slotsToLoad = [...pendingSlots];
    pendingSlots = [];

    loadBatchedAllSlots(slotsToLoad);

    addLifecycleEvent(
      `ADN Load batch of Ads for ${slotsToLoad.map((au) => au.targetId).join(', ')}`
    );

    debouncedSendMetrics();
  },
  { atBegin: false }
);

export function debouncedLoadAstTags(adUnit: AdnAdUnit) {
  pendingSlots.push(adUnit);
  const waitForClient = $waitForClient.get();

  if (waitForClient) {
    $waitForClient.subscribe((wait) => {
      if (!wait) {
        debounceLoadAd();
      }
    });
  } else {
    debounceLoadAd();
  }
}

export function getDebouncedLoadAdFunction() {
  return debouncedLoadAstTags;
}
