import { ConsentStatus, debugLog } from '@schibsted-nmp/advertising-shared';

const CONSENT_TIMEOUT_IN_MS = 10000; // 10 seconds

function subscribeToConsent(cb: (consentValue: boolean) => void): void {
  window?._tcf_?.isConsentedToAll(cb);
}

function getConsentSync(): boolean {
  return window?._tcf_?.getConsentedToAllSync();
}

export function waitForConsent(): Promise<ConsentStatus> {
  return new Promise((resolve) => {
    const consentValueSync = getConsentSync();
    const consentNotSetYet =
      consentValueSync === null || consentValueSync === undefined;

    if (consentNotSetYet) {
      let cancelled = false;

      const timer = setTimeout(() => {
        cancelled = true;
        debugLog(
          `Consent timeout after ${CONSENT_TIMEOUT_IN_MS}ms, defaulting to rejected`
        );
        resolve(ConsentStatus.Rejected);
      }, CONSENT_TIMEOUT_IN_MS);

      subscribeToConsent((consentValueAsync) => {
        if (!cancelled) {
          clearTimeout(timer);
          debugLog('Consent value async: ', consentValueAsync);
          resolve(
            consentValueAsync ? ConsentStatus.Accepted : ConsentStatus.Rejected
          );
        }
      });
    } else {
      debugLog('Consent value sync: ', consentValueSync);
      resolve(
        consentValueSync ? ConsentStatus.Accepted : ConsentStatus.Rejected
      );
    }
  });
}
