import { getPersistedGlimrTags } from '@client/core/utils/glimr';
import { PPID_STORAGE_NAME } from '@client/core/utils/pulse/pulse';
import { atom } from '@podium/store';
import {
  AdConfig,
  InitialState,
  PlacementId,
  PPIDResponse,
  SearchParams
} from '@schibsted-nmp/advertising-shared';

import localStorage from '../utils/localStorage';

import { AtomChannels, AtomTopics } from '.';

//
// ATOMS AND TYPES
//
export const $config = atom<AdConfig>(
  AtomChannels.Podlet,
  AtomTopics.Config,
  {} as AdConfig
);
export const $shouldPreloadPlacementIds = atom<PlacementId[]>(
  AtomChannels.Podlet,
  AtomTopics.PrebidLoadedList,
  []
);

export const $ppidAtom = atom<PPIDResponse>(
  AtomChannels.Podlet,
  AtomTopics.PPID,
  getEmptyOrPersistedPPID()
);

export const $waitForClient = atom<boolean>(
  AtomChannels.Podlet,
  AtomTopics.WaitForClient,
  false
);

export const $glimrTagsAtom = atom<string[]>(
  AtomChannels.Podlet,
  AtomTopics.Glimr,
  getInitialPersistedGlimr()
);

type SearchFilters = {
  previous: SearchParams;
  current: SearchParams;
};

export const $searchFilters = atom<SearchFilters>(
  AtomChannels.Podlet,
  AtomTopics.SearchFilters,
  {
    previous: {},
    current: {}
  } as SearchFilters
);

type ClientInitialState = Pick<
  InitialState,
  'env' | 'baseUrl' | 'logSessionId'
>;

export const $initialState = atom<ClientInitialState>(
  AtomChannels.Podlet,
  AtomTopics.InitialState,
  {} as ClientInitialState
);

export const emptyPpId = {
  delta: {
    ppId1: '',
    ppId2: '',
    adId: ''
  }
};

export function getEmptyOrPersistedPPID(): PPIDResponse {
  const persistedPPID = localStorage.getItem(PPID_STORAGE_NAME);
  if (persistedPPID) {
    return persistedPPID;
  }
  return emptyPpId;
}

export function getInitialPersistedGlimr() {
  const persistedGlimr = getPersistedGlimrTags();
  if (persistedGlimr?.length) {
    return persistedGlimr;
  }
  return [];
}
