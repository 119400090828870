import { initiateGam } from '@client/adManager/initiateGam';
import { initiateAdn } from '@client/adnuntius/initiateAdn';
import { triggerFinnAfsRequest } from '@client/adsense/finn-afs';
import { setupEvents } from '@client/core//utils/events';
import { defineAdvtComponent } from '@client/core/components/webComponents';
import { sendErrorMessageMetricToServer } from '@client/core/services/metrics';
import { persistPPID } from '@client/core/utils/pulse/pulse';
import {
  ConsentStatus,
  debugLog,
  InitialState
} from '@schibsted-nmp/advertising-shared';
import { initiateHandleStickySkyscrapers } from '@client/core/utils/dom/handleStickySkyscrapers';
import { initiateHandleWallpaper } from '@client/core/utils/dom/handleWallpaper';

import { loadGlimrIfPermission } from './core/utils/glimr';
import './dev/AdvtDevTool';
import { $consentAtom } from './core/atoms/consent';

/**
 * Initiates ad rendering, setup page options and handles each placement.
 * @param initialState The initial state for setting up ads.
 */
export async function initiateAdvt(initialState: InitialState): Promise<void> {
  try {
    debugLog('Inside initiateAdvt()');

    const { config } = initialState;

    await Promise.all([
      await persistPPID(),
      await loadGlimrIfPermission(config.brand)
    ]);

    setupEvents();

    if (config.deviceType === 'desktop') {
      initiateHandleStickySkyscrapers();
    }

    initiateHandleWallpaper();

    if (config.brand?.toLowerCase() === 'finn') {
      triggerFinnAfsRequest();
    }

    defineAdvtComponent();

    const consent = $consentAtom.get();
    if (config.adServer.gam) {
      if (consent === ConsentStatus.Accepted || config.brand !== 'dba') {
        initiateGam(config.adServer.gam);
      }
    }

    if (consent === ConsentStatus.Rejected && config.adServer.adn) {
      initiateAdn(config.adServer.adn);
    }

    debugLog('Advt initiated');
  } catch (e) {
    console.error('Failed to initiate ads', e);
    sendErrorMessageMetricToServer(`Failed to initiate ads ${e.message}`).then(
      () => {
        debugLog('Sent error message metric to server');
      }
    );
  }
}
