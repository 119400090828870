import { atom } from '@podium/store';

import { AtomChannels, AtomTopics } from '.';

interface WallpaperConfig {
  clickThroughUrl: string;
  wallpaperImage: string | null;
  wallpaperHtml: string | null;
  stickyWallpaper: boolean;
  stickySkyscrapers: boolean;
}

export const $wallpaperConfig = atom<WallpaperConfig>(
  AtomChannels.Podlet,
  AtomTopics.Cycle,
  {
    clickThroughUrl: '',
    wallpaperImage: '',
    wallpaperHtml: '',
    stickySkyscrapers: true,
    stickyWallpaper: false
  }
);
