import { atom } from '@podium/store';
import { ConsentStatus } from '@schibsted-nmp/advertising-shared';

import { AtomChannels, AtomTopics } from './index';

export const $consentAtom = atom<ConsentStatus>(
  AtomChannels.Podlet,
  AtomTopics.Consent,
  ConsentStatus.Accepted
);
