import { EventListenerCallbackEvent } from '@schibsted-nmp/advertising-shared';

export function sizeIsNativeAd(size: number[]): boolean {
  return Array.isArray(size) && size[0] === 0 && size[1] === 0;
}

export function eventIsNativeCpmAd(event: EventListenerCallbackEvent): boolean {
  const nativeCpmCreativeTemplateIds = [12399765];
  return Boolean(
    event.creativeTemplateId &&
      nativeCpmCreativeTemplateIds.includes(event.creativeTemplateId)
  );
}

export function sizeIsFullScreenAd(size: number[]): boolean {
  return Array.isArray(size) && size[0] === 1920 && size[1] === 1080;
}

export function sizeIsAdnami(size: number[]): boolean {
  return Array.isArray(size) && size[0] === 1 && size[1] === 2;
}

export function sizeIsDbaMiddleDesktop(size: number[]): boolean {
  return (
    sizeIsDbaDesktopIntermingle(size) || sizeIsDbaAdnamiMidscrollDesktop(size)
  );
}

export function sizeIsDbaDesktopIntermingle(size: number[]): boolean {
  return Array.isArray(size) && size[0] === 930 && size[1] === 180;
}

export function sizeIsDbaAdnamiMidscrollDesktop(size: number[]): boolean {
  return Array.isArray(size) && size[0] === 970 && size[1] === 570;
}
