import { $config } from '@client/core/atoms/config';
import { getInterminglePlacements } from '@client/core/atoms/placements';
import { setupTravelEvents } from '@client/core/utils/events/setupTravelEvents';
import { MessageBus } from '@podium/browser';
import { debugLog } from '@schibsted-nmp/advertising-shared';

import { refreshKeywords } from '../refreshKeywords';
import { onExpandableUpdateCheckMiddleBannerOverlap } from '../dom/handleAdsOverlap/isMiddleBannerOverlappingFilters';

export function setupEvents() {
  const messageBus = new MessageBus();

  const { pageType, vertical } = $config.get();

  // Keep this for backward compatibility
  const interminglePlacements = getInterminglePlacements();
  debugLog('placementsWithIntermingle: ', interminglePlacements);
  if (interminglePlacements.length > 0) {
    messageBus.publish('advertising-podlet', 'podlet-ready', {
      placementsWithIntermingle: interminglePlacements
    });
  }

  if (vertical === 'travel') {
    setupTravelEvents();
  } else if (pageType === 'result') {
    messageBus.subscribe('search', 'filters', refreshKeywords);
    messageBus.subscribe(
      'expandable',
      'updated',
      onExpandableUpdateCheckMiddleBannerOverlap
    );
  }
}
