import type { Api } from '../Api';
import { ConsentManagement } from './ConsentManagement';

export namespace PrebidConfig {
  export function apply(
    relevantDigital: Api.Loaded,
    customConsentVendorsMap: ConsentManagement.CustomVendors.Map
  ) {
    relevantDigital.addPrebidConfig({
      enableTIDs: true,

      consentManagement: {
        gdpr: {
          cmpApi: 'iab',
          timeout: 1000,
          defaultGdprScope: true,
          rules: ConsentManagement.Rules.from(customConsentVendorsMap)
        }
      },

      schain: {
        config: {
          ver: '1.0',
          complete: 1,
          nodes: []
        }
      },

      userSync: {
        iframeEnabled: true,
        filterSettings: {
          iframe: {
            bidders: '*',
            filter: 'include'
          }
        }
      }
    });
  }
}
