import { UNLEASH_FEATURE_NAME } from '@schibsted-nmp/advertising-shared';
import { isFeatureEnabled } from '@client/core/atoms/unleashFeatures';
import type { Script } from '@client/core/utils/Script';

import { Helpers } from './Helpers';
import type { Options } from './Options';
import { Settings } from './Settings';

export namespace Scripts {
  export function get(): get.ReturnValue;
  export function get(options: Options): get.ReturnValue;
  export function* get(options?: Options): get.ReturnValue {
    if (!isFeatureEnabled(UNLEASH_FEATURE_NAME.enableGamPrebid)) return;

    const parameters = Helpers.createParameters(options);
    if (!parameters) return;

    const { brandConfig, path } = parameters;

    const accountId = Settings.get('accountId', brandConfig, path);
    if (!accountId) return;

    const tagName = Settings.get('tagName', brandConfig, path);
    if (!tagName) return;

    yield {
      name: 'relevant-digital-gam',
      src: `https://${accountId}-cdn.relevant-digital.com/static/tags/${tagName}.js`
    };
  }

  export namespace get {
    export type ReturnValue = Generator<Script, void, void>;
  }
}
