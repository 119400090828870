import { html } from 'lit';

import { triggerAfsRequest } from '@client/adsense';
import { AdVendor } from '@client/core/AdVendor';
import { $config } from '@client/core/atoms/config';
import { isFeatureEnabled } from '@client/core/atoms/unleashFeatures';
import {
  AdConfigPlacement,
  AdConfigServer,
  AdServer,
  AfsPlacementConfig,
  AfsQuery,
  PlacementId,
  UNLEASH_FEATURE_NAME,
  debugLog
} from '@schibsted-nmp/advertising-shared';

import { $afsAtom } from '../core/atoms/afs';

export class AfsAdVendor extends AdVendor {
  placementId: PlacementId;

  /**
   * This data is sent down as a param from the Podium Layout Server, and is used to
   * determine the query to be used for the AFS request. It will be replaced by server values
   * eventually.
   */
  afsQuery?: AfsQuery;

  constructor(placementId: PlacementId, afsQuery?: AfsQuery) {
    super();
    this.adServer = AdServer.AFS;
    this.placementId = placementId;
    this.containerId = placementId;
    this.afsQuery = afsQuery;
  }

  updated() {}

  cleanupEvents(): void {}

  setupEvents(): void {
    this.cleanupEvents = $afsAtom.subscribe((afs) => {
      if (afs.config) {
        this.triggerAfsRequest({
          afsConfig: afs.config,
          placements: afs.placements
        });
      }
    });
  }

  private triggerAfsRequest({
    afsConfig,
    placements
  }: {
    afsConfig: AdConfigServer['afs'];
    placements: AdConfigPlacement[];
  }): void {
    if (!afsConfig) return;
    const afsPlacementConfig = placements.find(
      (placement) => placement.placementId === this.placementId
    )?.adServer.config as AfsPlacementConfig;
    const styleId = afsPlacementConfig?.styleId;
    if (!styleId) {
      debugLog(`No styleId found for container: ${this.placementId}`);
      return;
    }

    const overrideAfsPubId = isFeatureEnabled(
      UNLEASH_FEATURE_NAME.overrideAfsPubId
    );
    const overrideConfig = overrideAfsPubId
      ? {
          pubId: 'partner-pub-6408936348506194',
          styleId:
            $config.get().deviceType === 'desktop' ? '5268347338' : '3955265035'
        }
      : {};
    const afsPageOptions = {
      adPage: afsConfig.adPage,
      pubId: afsConfig.pubId,
      query: afsConfig.query,
      styleId,
      number: 1,
      ...overrideConfig
    };
    if (styleId) {
      triggerAfsRequest({
        container: this.placementId,
        ...afsPageOptions
      });
    }
  }

  requestAd(): void {
    // AFS is triggered by the config event listener, do nothing here
  }

  render() {
    return html`
      <slot
        name="${`${this.placementId}--slot`}"
        id="${this.placementId}"
      ></slot>
    `;
  }
}
