import { debugLog } from '@schibsted-nmp/advertising-shared';

import type { Api } from '../Api';
import type { Pbjs } from '../Pbjs';
import type { PrebidRequester } from './PrebidRequester';

export type Targeting = Readonly<{
  [key: string]: (bidResponse: Pbjs.Common.BidResponse) => string;

  [Symbol.iterator](
    this: Targeting
  ): Generator<Pbjs.BidderSettings.AdserverTargetingValue, void, void>;
}>;

export namespace Targeting {
  export type Key = string;

  export type Value = readonly [
    value: string,
    ...values: ReadonlyArray<string>
  ];

  export type KeyValue = readonly [key: Key, value: Value];

  export namespace KeyValue {
    export function of(key: Key, ...value: Value): KeyValue {
      return [key, value] as const;
    }

    export type Map = globalThis.Map<Key, Value>;
  }
}

export namespace Targeting.Mappers {
  export const base = {
    hb_bidder: ({ bidderCode }) => bidderCode,
    hb_size: ({ size }) => size,
    hb_adid: ({ adId }) => adId,
    hb_deal: ({ dealId }) => (dealId ? `${dealId}` : ''),
    hb_dealId: ({ dealId }) => (dealId ? `${dealId}` : ''),
    hb_pb: ({ pbCg }) => pbCg,
    hb_source: ({ source }) => source,
    hb_format: ({ mediaType }) => mediaType,
    g_adid: ({ adId }) => adId,

    *[Symbol.iterator]() {
      for (const [key, val] of Object.entries(this)) {
        yield { key, val } as const;
      }
    }
  } as const satisfies Targeting;

  export function* get(pbjs: PrebidRequester['pbjs']) {
    const targetingMappers = {
      ...base,
      g_prebid_version: () => pbjs.version ?? 'unknown'
    } as const satisfies Targeting;

    yield* targetingMappers;
  }
}

export namespace Targeting.PageLevel {
  export type AdServerType = keyof typeof set.forAdServerType;

  export function applyOnBeforeAdRequest(
    relevantDigital: Api.Loaded,
    pbjs: PrebidRequester['pbjs']
  ) {
    relevantDigital.addAuctionCallbacks({
      onBeforeAdRequest(parameters) {
        try {
          const { auction } = parameters;
          const { auctionId, adservers } = auction;
          const events = pbjs.getEvents();

          const targeting = new Map<Targeting.Key, Targeting.Value>([
            ...Targeting.PageLevel.TakeoverDealId.get(auctionId, events)
          ]);

          debugLog(
            `Setting page level targeting with Relevant Yield`,
            targeting,
            auction
          );

          for (const { type } of adservers) {
            Targeting.PageLevel.set(type, targeting);
          }
        } catch (error) {
          debugLog(
            'Failed setting page level targeting with Relevant Yield',
            error,
            parameters
          );
        }
      }
    });
  }

  export function set(adServerType: AdServerType, targeting: KeyValue.Map) {
    try {
      const setForAdServerType = set.forAdServerType[adServerType];

      if (!setForAdServerType) {
        throw new ReferenceError(
          `No setter found for ad server type '${adServerType}'`
        );
      }

      debugLog(
        `Setting page level targeting with Relevant Yield for ad server type '${adServerType}'`,
        targeting
      );

      setForAdServerType(targeting);
    } catch (error) {
      debugLog(
        `Failed setting page level targeting with Relevant Yield for ad server type '${adServerType}'`,
        targeting,
        error
      );
    }
  }

  export namespace set {
    export const forAdServerType = {
      GamAdserver(targeting) {
        if (!window.googletag?.cmd) return;

        window.googletag.cmd.push(() => {
          for (const [key, value] of targeting) {
            window.googletag.pubads().setTargeting(key, Array.from(value));
          }
        });
      },

      AdnuntiusAdserver(_targeting) {
        // currently unhandled
      }
    } as const satisfies {
      [adServerType: string]: (targeting: KeyValue.Map) => void;
    };
  }

  export namespace TakeoverDealId {
    export function* getDealBids(
      auctionId: string,
      events: ReturnType<PrebidRequester['pbjs']['getEvents']>
    ) {
      for (const { eventType, args: bid } of events) {
        if (eventType !== 'bidResponse') continue;
        if (bid.auctionId !== auctionId) continue;
        if (!bid.dealId) continue;
        if (!bid.adUnitCode.includes('top')) continue;

        const { dealId, cpm } = bid;
        yield { dealId, cpm } as const;
      }
    }

    export function* get(
      auctionId: string,
      events: ReturnType<PrebidRequester['pbjs']['getEvents']>
    ) {
      let winningBid: Readonly<{
        dealId: string | number;
        cpm: number;
      }> | null = null;

      for (const bid of getDealBids(auctionId, events)) {
        if (!winningBid || winningBid.cpm < bid.cpm) winningBid = bid;
      }

      if (!winningBid) return;

      yield [
        'hb_takeover_dealId',
        [`${winningBid.dealId}`]
      ] as const satisfies Targeting.KeyValue;
    }
  }
}
