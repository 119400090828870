import { $wallpaperConfig } from '@client/core/atoms/wallpaperConfigAtom';
import {
  fetchAllElements,
  fetchElement,
  styleElements
} from '@client/core/utils/dom/domUtils';
import { debugLog } from '@schibsted-nmp/advertising-shared';

const mainContentsQueries = [
  '#mobility-item-page-root',
  '#app',
  '.page-container',
  '#frontpage-content'
];

let observer;
let clickEvent;

export const initiateHandleWallpaper = () => {
  $wallpaperConfig.subscribe((config) => {
    if (!config.stickySkyscrapers) {
      handleSkyscraperStickySetting(false);
    }

    clickEvent = (event) => {
      if (event.defaultPrevented) return;
      window.open(config.clickThroughUrl, '_blank');
    };

    if (config.wallpaperImage || config.wallpaperHtml) {
      const { body } = document;
      body.style.pointerEvents = 'none';
      const wallpaperDiv = document.createElement(
        config.wallpaperHtml ? 'iframe' : 'div'
      );
      wallpaperDiv.id = 'advt-wallpaper-container';
      wallpaperDiv.style.zIndex = '-1';
      wallpaperDiv.style.pointerEvents = 'all';

      if (config.wallpaperImage) {
        wallpaperDiv.style.backgroundImage = `url(${config.wallpaperImage})`;
      } else if (config.wallpaperHtml) {
        (wallpaperDiv as HTMLIFrameElement).src = config.wallpaperHtml;
      }
      wallpaperDiv.style.top = `51px`;
      wallpaperDiv.style.backgroundPosition = 'center top';
      wallpaperDiv.style.height = config.stickyWallpaper ? '100vh' : '1080px';

      document.body.insertBefore(wallpaperDiv, document.body.firstChild);

      const header = fetchElement('finn-topbar');
      if (header) {
        header.style.marginBottom = '0';
      }

      const mainContents = fetchAllElements(
        `body > *:not(script)${mainContentsQueries.map((query) => `:not(${query})`).join('')}`
      );
      const mainContents2 = fetchAllElements(
        `${mainContentsQueries.map((query) => `body > ${query}`)}`
      );

      requestAnimationFrame(() => {
        mainContents.forEach((content) => {
          content.style.pointerEvents = 'all';
        });

        mainContents2.forEach((content) => {
          Array.from(content.children).forEach((child: HTMLElement) => {
            if (child.id !== 'banner-top-container') {
              child.style.pointerEvents = 'all';
            } else {
              child.style.pointerEvents = 'none';
              const actualTopBanner = child.children[0] as HTMLHtmlElement;
              if (actualTopBanner) actualTopBanner.style.pointerEvents = 'all';
            }
          });
        });
      });

      if (config.clickThroughUrl) {
        wallpaperDiv.style.cursor = 'pointer';
        wallpaperDiv.addEventListener('click', clickEvent);
      } else {
        wallpaperDiv.style.cursor = 'default';
      }
    }

    observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        mutation.addedNodes.forEach((node: HTMLElement) => {
          if (node.nodeType === Node.ELEMENT_NODE) {
            const element = node;
            if (element.tagName !== 'SCRIPT') {
              element.style.pointerEvents = 'all';
            }
          }
        });
      });
    });

    observer.observe(document.body, { childList: true });

    const parentElement = fetchElement('#advt_wallpaper');

    if (parentElement) {
      const observer = new MutationObserver((mutations) => {
        mutations.forEach((mutation) => {
          mutation.removedNodes.forEach((node) => {
            if (
              node instanceof HTMLIFrameElement ||
              node instanceof HTMLScriptElement
            ) {
              debugLog('Iframe or script removed! Running cleanup...');
              cleanup();
            }
          });

          // Check if the iframe/script is no longer in the DOM
          const iframeExists = parentElement.querySelector('iframe');
          const scriptExists = parentElement.querySelector('script');

          if (!iframeExists && !scriptExists) {
            debugLog('No iframe or script found! Running cleanup...');
            cleanup();
          }
        });
      });

      observer.observe(parentElement, { childList: true, subtree: true });
    }
    debugLog('wallpaper intiate');
  });
};

function handleSkyscraperStickySetting(sticky: boolean) {
  const stickyElements = fetchAllElements(
    '.banners__sticky--1 .banners__skyscraper'
  );
  styleElements(stickyElements, {
    position: sticky ? 'sticky' : 'static'
  });
}

export function cleanup() {
  debugLog('wallpaper cleanup');

  const wallpaperElement = fetchElement('#advt-wallpaper-container');
  if (wallpaperElement) wallpaperElement.remove();

  if (observer) {
    observer.disconnect();
    observer = null;
  }
  window.removeEventListener('beforeunload', cleanup);

  handleSkyscraperStickySetting(true);
}
